import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { Fade } from '../global/GlobalStyle.js';


const CenterAlign = styled.div`
  text-align: center;
`;

const landingStyle = {
  display: 'table-cell',
  verticalAlign: 'middle',
};

export const LandingLayout = (props) => (

  <Container style={landingStyle} className="landing-container">
    {/* anything within the Container will be considered Props.children */}
    <CenterAlign>
      <Row>
        <Col>
          <Fade>
            { props.children }
          </Fade>
        </Col>
      </Row>
    </CenterAlign>
  </Container>
)