import React from 'react';
import styled from 'styled-components';
import { CentralLayout } from '../components/layouts/CentralLayout.js';
import { TitleBar, SocialIcon } from '../components/global/GlobalStyle.js';
import Hadih from '../assets/cKpUNTemZFEh.png';
import Tansi from '../assets/cKpUNTemZFEt.png';
import IGIcon from '../assets/cKpUNTueYJGIg.png';

const PadTop = {
  marginTop: '1em'
}

const ResponsiveWidth = styled.div`
  .connect {
    width: '30%',
    height: 'auto',
    margin: '1em'
  }

  @media screen and (min-width: 300px){
    .connect {
      width: 60%;
    }

  }

  @media screen and (min-width: 480px){
  }

  @media screen and (min-width: 768px){
    .connect {
      width: 30%;
    }
  }

  @media screen and (min-width: 1300px){
  }
`

export class Connect extends React.Component {
  componentDidMount() {
    let imageList = [Hadih, Tansi]
    imageList.forEach((image) => {
        new Image().src = image
    });
  }
  render () {
    return (
      <CentralLayout>
        <h1>Let's Connect</h1>
        <TitleBar />
        <br/>
        <ResponsiveWidth>
          <img className="connect" src={ Hadih } alt=""/>
        </ResponsiveWidth>
        <p style={ PadTop } >For my Neechies:</p>
        <ResponsiveWidth>
          <img className="connect" src={ Tansi } alt=""/>
        </ResponsiveWidth>
        <br/>

      </CentralLayout>
    );
  }
}

        // <SocialIcon>
        //   <a href="https://www.instagram.com/kooki_mooki" target="_blank" rel="noopener noreferrer">
        //     <img src={ IGIcon } alt="www.instagram.com/kooki_mooki" />
        //   </a>
        //  </SocialIcon>