import React from 'react';
import { CentralLayout } from '../components/layouts/CentralLayout.js';
import Button from '../components/button/Button.js';
import { Link } from "react-router-dom";
import { TitleBar } from '../components/global/GlobalStyle.js';

export const NoMatch = () => (
  <CentralLayout>
    <h1>You lost, bruh?</h1>
    <TitleBar />
    <br/>
    <Link to="/">
      <Button primary>take me home</Button>
    </Link>
  </CentralLayout>
)