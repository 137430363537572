import React from 'react';
import { CentralLayout } from '../components/layouts/CentralLayout.js';
import { TitleBar, SocialIcon } from '../components/global/GlobalStyle.js';
import IGIcon from '../assets/cKpUNTueYJGIg.png';

const Caption = {
  fontStyle: 'italic',
  fontSize: 16
}

const TAcknowledge = {
  opacity: 0.8,
  fontStyle: 'italic',
  fontSize: 16,
  color: '#73272D'
}

export class Me extends React.Component {
  render () {
    return (
      <CentralLayout>
        <h1>Hadih, Tansi, Hello</h1>
        <TitleBar />
        <h3>Multimedia Artist & Web Developer</h3>
        <p>I'm a creative nerd that likes to mash art and tech together.</p>
        <br />
        <h3>Education</h3>
        <p>Bachelor of Design<br/><span style={ Caption }>Alberta University of the Arts</span></p>
        <p>Web Development Certificate<br/><span style={ Caption }>Lighthouse Labs</span></p>
        <p>iOS Development Certificate<br/><span style={ Caption }>Brainstation</span></p>
        <br/>
        <h3>Tidbits</h3>
        <p>Budding XR Dev, 3D modeller & 2019 Oculus Launch Pad Alumnus</p>

        <p><span style={ TAcknowledge }>I am grateful to work and live in the unceded ancestral territories of the Sḵwx̱wú7mesh (Squamish), səl̓ílwətaʔɬ/Selilwitulh (Tsleil-Waututh) and xʷməθkʷəy̓əm (Musqueam) Peoples.</span>
        </p>
        <br/>

      </CentralLayout>
    );
  }
}