import React from 'react';
import { LandingLayout } from '../components/layouts/LandingLayout.js';
import logo from '../assets/logo/logo.svg';
import { Link } from "react-router-dom";
// import './App.css';

export class Landing extends React.Component {
  render () {
    return (
      <LandingLayout>
        <div>
          <img src={logo} width="100%" alt="kooki mooki" />
          <br/>
          <br/>
          <h3>
            <Link to="/art">art</Link>
            &nbsp;//&nbsp;
            <Link to="/design">design</Link>
            &nbsp;\\&nbsp;
            <Link to="/web">web</Link>
          </h3>
        </div>
      </LandingLayout>
    );
  }
}
