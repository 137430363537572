import React from 'react';
// STYLES
import { Header } from './components/header/Header.js';
import { GlobalStyle, SiteContainer, SiteContent } from './components/global/GlobalStyle.js';
import { Footer } from './components/footer/Footer.js';
// ROUTES
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Landing } from './views/Landing.js';
import { Me } from './views/Me.js';
import { Art } from './views/Art.js';
import { Design } from './views/Design.js';
import { Web } from './views/Web.js';
// import { Tatt } from './views/Tatt.js';
import { Connect } from './views/Connect.js';
import { NoMatch } from './views/NoMatch.js';

window.onload = function() {
  document.addEventListener("contextmenu", function(e){
    e.preventDefault();
  }, false);
  document.addEventListener("keydown", function(e) {
  //document.onkeydown = function(e) {
    // "I" key
    if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
      disabledEvent(e);
    }
    // "J" key
    if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
      disabledEvent(e);
    }
    // "S" key + macOS
    if (e.keyCode === 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
      disabledEvent(e);
    }
    // "U" key
    if (e.ctrlKey && e.keyCode === 85) {
      disabledEvent(e);
    }
    // "F12" key
    if (e.keyCode === 123) {
      disabledEvent(e);
    }
  }, false);
  function disabledEvent(e){
    if (e.stopPropagation){
      e.stopPropagation();
    } else if (window.event){
      window.e.cancelBubble = true;
    }
    e.preventDefault();
    return false;
  }
};

class App extends React.Component {
  render() {
    return (
        <SiteContainer>
          <Router>
            <Header />
            <GlobalStyle />
            <SiteContent>
              {/* props.children */}
              <Switch>
                <Route exact path="/" component={ Landing } />
                <Route path="/me" component={ Me } />
                <Route path="/art" component={ Art } />
                <Route path="/design" component={ Design } />
                <Route path="/web" component={ Web } />
                 {/* <Route path="/tatt" component={ Tatt } /> */}
                <Route path="/connect" component={ Connect } />
                <Route component={ NoMatch } />
              </Switch>
            </SiteContent>
            <Footer />
          </Router>
        </SiteContainer>
    );
  }
}

export default App;
