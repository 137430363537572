// import { ThemeProvider } from 'styled-components';
import styled, { keyframes } from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import background1 from '../../assets/cKpUNTemNFYd.png';
import background2 from '../../assets/cKpUNTemNFYm.png';
import creative from '../../assets/cKpUNTemZFEc.jpg';
import close from '../../assets/cKpUNTemNFYx.png';

// OLD GREEN: #00272d; rgb(0, 39, 45);
// OlD 2 GREEN: #001c21; rgb(0, 28, 33);
// NEW GREEN: #031417; rgb(0, 20, 23);
// RED: #73272d; rgb(115, 39 45);

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300&display=swap');
  body {
    background-color: ${props => props.green || "#001c21"};
    color: ${props => props.white || "#fff"};
    margin: 0;
    padding: 0;
    height: 80vh;
    width: 100vw;
    font-family: ${props => props.font || "'Open Sans Condensed' , 'Arial Narrow' , helvetica, sans-serif !important;"};
     -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6, p {
    font-weight: 600;
    text-shadow: 0 2px 2px #000;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  a:hover, a:focus{
    color: #73272d;
    text-decoration: none;
    font-weight: bolder;
  }

  p {
    font-size: 1.25em;
  }

  .caption {
    font-size: 1em;
  }

  img {
    position: relative;
    top: 0;
    left: 0;
  }

  .lightbox {
    :after {
      content: "";
      display: inline-block;
      /* background-position: center center; */
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      opacity: 1;
      top: 20px;
      right: 20px;
      z-index: 10;
      background-image: url('${ close }');
      position: absolute;
      background-size: cover;
    }
  }

  .lightbox img {
    /** Pad the lightbox image */
    max-width: 90%;
    max-height: 80%;
  }

  .vcreative1 {
    :before {
      content: "";
      display: inline-block;
      /* background-position: center center; */
      background-repeat: no-repeat;
      width: 150px;
      height: 200px;
      opacity: 0;
      z-index: 10;
      background-image: url('${ creative }');
      /* background-size: contain; */
      position: absolute;
    }
  }

  .vcreative2 {
    :before {
      content: "";
      display: inline-block;
      /* background-position: center center; */
      background-repeat: no-repeat;
      width: 350px;
      height: 450px;
      opacity: 0;
      z-index: 10;
      background-image: url('${ creative }');
      /* background-size: contain; */
      position: absolute;
      margin-top: 10%;
    }
  }

  .hcreative1 {
    :before {
      content: "";
      display: inline-block;
      /* background-position: center center; */
      background-repeat: no-repeat;
      width: 203px;
      height: 157.88px;
      opacity: 0;
      z-index: 10;
      background-image: url('${ creative }');
      /* background-size: contain; */
      position: absolute;
    }
  }

  .hcreative2 {
    :before {
      content: "";
      display: inline-block;
      /* background-position: center center; */
      background-repeat: no-repeat;
      width: 450px;
      height: 350px;
      opacity: 0;
      z-index: 10;
      background-image: url('${ creative }');
      /* background-size: contain; */
      position: absolute;
      margin-top: 10%;
    }
  }

  .loader:empty {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    background-color: ${props => props.green || "#001c21"};
    border: 1.1em solid rgba(115, 39, 45, 0.2);
    border-left: 1.1em solid #73272d;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
  }

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media screen and (min-width: 300px){
    .lightbox img {
      margin-top: 15%;
    }

    .landing-container {
      height: 100vh;
    }
  }

  @media screen and (min-width: 480px){
  }

  @media screen and (min-width: 768px){
    .lightbox img {
      margin-top: 10%;
    }

    .landing-container {
      height: inherit;
    }
  }

  @media screen and (min-width: 1200px){
  }
`

export const SiteContent = styled.div`
  padding: 1em;
  display: table;
  width: 100%;
  position: relative;

  :before {
    content : "";
    display: block;
    position: absolute;
    top: -2em;
    left: 0;
    background-position: center center;
    background-repeat: no-repeat space;
    width: 100%;
    opacity : 1;
    z-index: -1;
  }

  @media screen and (min-width: 300px){
    padding: 0;
    :before {
      background-image: url('${background2}');
      background-size: cover;
      height: 50em;
      filter: blur(0px);
      -webkit-filter: blur(0px);
    }
  }

  @media screen and (min-width: 480px){
    padding: 2em 4em;
    height: 60%;
  }

  @media screen and (min-width: 768px){
    padding: 3em 6em;
    height: 50%;
     :before {
      background-image: url('${background1}');
      height: 30em;
      filter: blur(0.5px);
      -webkit-filter: blur(0.5px);
    }
  }

  @media screen and (min-width: 1300px){
    padding: 4em 8em;
    height: 40%;
    :before {
      background-image: url('${background1}');
      background-size: contain;
    }
  }
`;

export const SocialIcon = styled.div`
  margin: 10px;
  display: flex;
  justify-content: center;

  img {
    object-fit: contain;
    object-position: 50% 50%;
    height: 50px;
    width: 50px;
  }
`;


export const TitleBar = styled.div`
  height: 5px;
  width: 150px;
  background-color: #fff;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  display: inline-block;
  box-shadow: 0 2px 2px #000;
`;

export const SiteContainer = styled.div`
  height: 100vh;
  width: 100vw;
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Fade = styled.div`
  display: inline-block;
  visibility: ${props => props.out ? 'hidden' : 'visible'};
  animation: ${props => props.out ? fadeOut : fadeIn} 0.25s linear;
  transition: visibility 0.25s linear;
`;

export default { GlobalStyle, SiteContainer, SiteContent, Fade, TitleBar, SocialIcon };
