import React from 'react';
import { CentralLayout } from '../components/layouts/CentralLayout.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled, { keyframes } from 'styled-components';
import Button from '../components/button/Button.js';
import { Gallery, GalleryImage } from '../components/layouts/Gallery.js';
import { TitleBar } from '../components/global/GlobalStyle.js';
import Naloxone from '../assets/cKpUNTueYCFYn.png';
import NaloxoneIcon from '../assets/cKpUNTueYCFXn.jpg';
import Sic from '../assets/cKpUNTueYCFYs.png';
import SicIcon from '../assets/cKpUNTueYCFXs.jpg';
import Mvaec from '../assets/cKpUNTueYCFYm.png';
import MvaecIcon from '../assets/cKpUNTueYCFXm.jpg';
import Im4Icon from '../assets/cKpUNTemZFWf.jpg';
import Im4 from '../assets/cKpUNTemZFWm.png';

export const fadeIn = keyframes`
  from {
    opacity: 0;
    scale(0.25);
  }

  to {
    opacity: 1;
    scale(1);
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 0;
    scale(1);
  }

  to {
    opacity: 1;
    scale(0.25);
  }
`;

const Lightbox = styled.div`

.caption {
  font-size: 1em;
}
/** LIGHTBOX MARKUP **/

.thumbnail {
  width: 100%;
}

.lightbox {
  /** Default lightbox to hidden */
  display: none;

  /** Position and style */
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
  color: #fff;
}


// .lightbox img {
//   /** Pad the lightbox image */
//   max-width: 90%;
//   max-height: 80%;
//   margin-top: 10%;
// }

.lightbox:target {
  /** Remove default browser outline */
  outline: none;

  /** Unhide lightbox **/
  display: block;
  visibility: ${props => props.out ? 'hidden' : 'visible'};
  animation: ${props => props.out ? fadeOut : fadeIn} 0.25s linear;
  transition: visibility 0.25s linear;
}
`;

const HThumbWidth = {
  width: '150px',
  height: '230px',
  marginBottom: '50px'
}


const FullWidth = {
}


const DivContain = {
  width: '80%',
  height: '80%',
  display: 'inline-block'
}

const RowCenter = {
  justifyContent: 'center',
  marginLeft: '50px',
  marginRight: '50px'
}

export class Web extends React.Component {
  constructor(props) {
    super(props);
    this.showCategory = this.showCategory.bind(this);
  }

  componentDidMount() {
    let imageList = [Naloxone, NaloxoneIcon, SicIcon, Sic, MvaecIcon, MvaecIcon, Im4Icon, Im4]
    imageList.forEach((image) => {
        new Image().src = image
    });
  }

  showCategory(e) {
    const category = e.target.id;
    const all = document.getElementsByClassName('all');
    for (var i = 0; i < all.length; i++) {
      const classList = all[i].classList;

      for (var l = 0; l < classList.length; l++) {
        if ( (classList[l] === category) ) {
          if ( (all[i].hidden = true) ) {
            all[i].hidden = false;
            all[i].style.visibility = 'visible';
            break;
          } else {
            all[i].hidden = false;
            all[i].style.visibility = 'hidden';
            break;
          }
        } else {
          all[i].hidden = true;
          all[i].style.visibility = 'hidden';
        }
      }
    }
  }

  render () {
    return (
      <CentralLayout>
        <h1>Web Development</h1>
        <TitleBar />
        <p>My services include website development and website management.</p>
        <br/>
        <Row>
          <Col>
            <Button id="yikesite" onClick={ this.showCategory }>Yikesite</Button>
            <Button id="wordpress" onClick={ this.showCategory }>Wordpress</Button>
            <Button id="all" onClick={ this.showCategory }>All</Button>
          </Col>
        </Row>
        <br/>

      <Gallery>
          <Row style={ RowCenter }>
            <GalleryImage className="yikesite all"  style={ HThumbWidth }>
              <Lightbox>
                  <a href="#1">
                    <img className="thumbnail" src={ NaloxoneIcon } alt="" />
                  </a>
                  <div>
                    <p className="caption">Naloxone Training<br/>
                      Employer: Animikii
                    </p>
                    <a href="http://www.naloxonetraining.com" target="_blank" rel="noopener noreferrer">
                      <Button>visit</Button>
                    </a>
                  </div>
                  <a href="#_" className="lightbox" id="1">
                    <div style={ DivContain }>
                      <img src={ Naloxone } style={ FullWidth }  alt="" />
                      <p>Naloxone Training</p>
                    </div>
                  </a>
              </Lightbox>
            </GalleryImage>

            <GalleryImage className="yikesite all"  style={ HThumbWidth }>
              <Lightbox>
                  <a href="#2">
                    <img className="thumbnail" src={ MvaecIcon } alt="" />
                  </a>
                  <div>
                    <p className="caption">MVAEC<br/>
                      Employer: Animikii
                    </p>
                    <a href="http://www.mvaec.ca" target="_blank" rel="noopener noreferrer">
                      <Button>visit</Button>
                    </a>
                  </div>
                  <a href="#_" className="lightbox" id="2">
                    <div style={ DivContain }>
                      <img src={ Mvaec } style={ FullWidth }  alt="" />
                      <p>MVAEC</p>
                    </div>
                  </a>
              </Lightbox>
            </GalleryImage>
            <GalleryImage className="yikesite all"  style={ HThumbWidth }>
              <Lightbox>
                  <a href="#3">
                    <img className="thumbnail" src={ SicIcon } alt="" />
                  </a>
                  <div>
                    <p className="caption">Songhees Innovation Centre<br/>
                      Employer: Animikii
                    </p>
                    <a href="http://www.songheesinnovationcentre.ca" target="_blank" rel="noopener noreferrer">
                      <Button>visit</Button>
                    </a>
                  </div>
                  <a href="#_" className="lightbox" id="3">
                    <div style={ DivContain }>
                      <img src={ Sic } style={ FullWidth }  alt="" />
                      <p>Songhees Innovation Centre</p>
                    </div>
                  </a>
              </Lightbox>
            </GalleryImage>
            <GalleryImage className="wordpress all"  style={ HThumbWidth }>
              <Lightbox>
                  <a href="#4">
                    <img className="thumbnail" src={ Im4Icon } alt="" />
                  </a>
                  <div>
                    <p className="caption">Indigenous Matriarchs 4<br />
                    XR Media Lab</p>
                    <a href="http://im4lab.com" target="_blank" rel="noopener noreferrer">
                      <Button>visit</Button>
                    </a>
                  </div>
                  <a href="#_" className="lightbox" id="4">
                    <div style={ DivContain }>
                      <img src={ Im4 } style={ FullWidth }  alt="" />
                      <p>Indigenous Matriarchs 4</p>
                    </div>
                  </a>
              </Lightbox>
            </GalleryImage>
          </Row>
        </Gallery>
      </CentralLayout>
    );
  }
}