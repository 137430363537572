import React from 'react';
import { CentralLayout } from '../components/layouts/CentralLayout.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../components/button/Button.js';
import styled, { keyframes } from 'styled-components';
import { TitleBar, SocialIcon } from '../components/global/GlobalStyle.js';
import { Gallery, GalleryImage } from '../components/layouts/Gallery.js';
import IGIcon from '../assets/cKpUNTueYJGIg.png';
import VWise from '../assets/cKpUNTemZFYu.jpg';
import VLady from '../assets/cKpUNTemYPFTm.jpg';
import VSnow from '../assets/cKpUNTemYPFYr.jpg';
import VBoid from '../assets/cKpUNTemZFYn.jpg';
import VJester from '../assets/cKpUNTueYCFYr.jpg';
// import HBinky from '../assets/cKpUNTueYJEYr.jpg';
import HTrixieSketch from '../assets/cKpUNTueUAEYr.jpg';
// import HKammy from '../assets/cKpUNTueTREYr.jpg';
import HOsawask from '../assets/cKpUNTemZFEu.jpg';
// import HLooli from '../assets/cKpUNTemTFYu.jpg';
import HTrixie from '../assets/cKpUNTemYJEYr.jpg';
import HMuddy from '../assets/cKpUNTueYJEYm.jpg';
import HGuzzie from '../assets/cKpUNTueYJEYg.jpg';
import HMeow from '../assets/cKpUNTueYJEYc.jpg';
import HDune from '../assets/cKpUNTemTFYd.jpg';

export const fadeIn = keyframes`
  from {
    opacity: 0;
    scale(0.25);
  }

  to {
    opacity: 1;
    scale(1);
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 0;
    scale(1);
  }

  to {
    opacity: 1;
    scale(0.25);
  }
`;

const Lightbox = styled.div`
/** LIGHTBOX MARKUP **/

.thumbnail {
  width: 100%;
}

.lightbox {
  /** Default lightbox to hidden */
  display: none;

  /** Position and style */
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
  color: #fff;
}

.lightbox:target {
  /** Remove default browser outline */
  outline: none;

  /** Unhide lightbox **/
  display: block;
  visibility: ${props => props.out ? 'hidden' : 'visible'};
  animation: ${props => props.out ? fadeOut : fadeIn} 0.25s linear;
  transition: visibility 0.25s linear;
}
`;

const VThumbWidth = {
  width: '150px'
}

const HThumbWidth = {
  width: '203px',
}

let RowCenter = {
  justifyContent: 'center',
  margin: '0'
}

export class Art extends React.Component {
  constructor(props) {
    super(props);
    this.showCategory = this.showCategory.bind(this);
  }

  componentDidMount() {
    let imageList = [VWise, VBoid, VSnow, VJester, VLady, HTrixie, HTrixieSketch, HOsawask]
    imageList.forEach((image) => {
        new Image().src = image
    });
  }

  showCategory(e) {
    const category = e.target.id;
    const all = document.getElementsByClassName('art');
    for (var i = 0; i < all.length; i++) {
      const classList = all[i].classList;

      for (var l = 0; l < classList.length; l++) {
        if ( (classList[l] === category) ) {
          if ( (all[i].hidden = true) ) {
            all[i].hidden = false;
            all[i].style.visibility = 'visible';
            break;
          } else {
            all[i].hidden = false;
            all[i].style.visibility = 'hidden';
            break;
          }
        } else {
          all[i].hidden = true;
          all[i].style.visibility = 'hidden';
        }
      }
    }
  }

  render () {
    return (
      <CentralLayout>
        <h1>Art</h1>
        <TitleBar />
        <p>Art Prints available. Check back soon for merch & more eye candy.</p>
        <Row>
          <Col>
            <Button id="illustration" onClick={ this.showCategory }>Illustration</Button>
            <Button id="cdesign" onClick={ this.showCategory }>Character Design</Button>
            {/*<Button id="wip" onClick={ this.showCategory }>WIP</Button>*/}
            <Button id="sketch" onClick={ this.showCategory }>Sketch</Button>
            <Button id="art" onClick={ this.showCategory }>All</Button>
          </Col>
        </Row>
        <br/>

        <Gallery>
          <Row style={ RowCenter }>
            <GalleryImage className="illustration art" style={ VThumbWidth }>
              <Lightbox>
                <a href="#15" className="vcreative1">
                  <img className="thumbnail" src={ HGuzzie } alt="" />
                </a>
                <a href="#_" className="lightbox vcreative2" id="15">
                  <img src={ HGuzzie } alt="" />
                  <p>Guzzie</p>
                </a>
              </Lightbox>
            </GalleryImage>

            <GalleryImage className="illustration art" style={ VThumbWidth }>
              <Lightbox>
                <a href="#14" className="vcreative1">
                  <img className="thumbnail" src={ HDune } alt="" />
                </a>
                <a href="#_" className="lightbox vcreative2" id="14">
                  <img src={ HDune } alt="" />
                  <p>Dune</p>
                </a>
              </Lightbox>
            </GalleryImage>

            <GalleryImage className="illustration art" style={ VThumbWidth }>
              <Lightbox>
                <a href="#13" className="vcreative1">
                  <img className="thumbnail" src={ HMeow } alt="" />
                </a>
                <a href="#_" className="lightbox vcreative2" id="13">
                  <img src={ HMeow } alt="" />
                  <p>Meow</p>
                </a>
              </Lightbox>
            </GalleryImage>

            <GalleryImage className="illustration art" style={ VThumbWidth }>
              <Lightbox>
                <a href="#12" className="vcreative1">
                  <img className="thumbnail" src={ HMuddy } alt="" />
                </a>
                <a href="#_" className="lightbox vcreative2" id="12">
                  <img src={ HMuddy } alt="" />
                  <p>Muddy Waters</p>
                </a>
              </Lightbox>
            </GalleryImage>


            <GalleryImage className="illustration art" style={ VThumbWidth }>
              <Lightbox>
                <a href="#1" className="vcreative1">
                  <img className="thumbnail" src={ VWise } alt="" />
                </a>
                <a href="#_" className="lightbox vcreative2" id="1">
                  <img src={ VWise } alt="" />
                  <p>Wise One</p>
                </a>
              </Lightbox>
            </GalleryImage>

            <GalleryImage className="illustration art"  style={ VThumbWidth }>
              <Lightbox>
                  <a href="#2" className="vcreative1">
                    <img className="thumbnail" src={ VBoid } alt="" />
                  </a>
                  <a href="#_" className="lightbox vcreative2" id="2">
                    <img src={ VBoid } alt="" />
                    <p>Boid</p>
                  </a>
              </Lightbox>
            </GalleryImage>

            <GalleryImage className="illustration art"  style={ VThumbWidth }>
              <Lightbox>
                  <a href="#3" className="vcreative1">
                    <img className="thumbnail" src={ VSnow } alt="" />
                  </a>
                  <a href="#_" className="lightbox vcreative2" id="3">
                    <img src={ VSnow } alt="" />
                    <p>Snow White & The Seven Ginch</p>
                  </a>
              </Lightbox>
            </GalleryImage>

            <GalleryImage className="illustration art"  style={ VThumbWidth }>
              <Lightbox>
                  <a href="#4" className="vcreative1">
                    <img className="thumbnail" src={ VJester } alt="" />
                  </a>
                  <a href="#_" className="lightbox vcreative2" id="4">
                    <img src={ VJester } alt="" />
                    <p>Jester</p>
                  </a>
              </Lightbox>
            </GalleryImage>
            <GalleryImage className="illustration art"  style={ VThumbWidth }>
              <Lightbox>
                  <a href="#11" className="vcreative1">
                    <img className="thumbnail" src={ VLady } alt="" />
                  </a>
                  <a href="#_" className="lightbox vcreative2" id="11">
                    <img src={ VLady } alt="" />
                    <p>Old Lady</p>
                  </a>
              </Lightbox>
            </GalleryImage>
          </Row>
          <Row style={ RowCenter }>
            <GalleryImage className="sketch art"  style={ HThumbWidth }>
              <Lightbox>
                  <a href="#5" className="hcreative1">
                    <img className="thumbnail" src={ HTrixieSketch } alt="" />
                  </a>
                  <a href="#_" className="lightbox hcreative2" id="5">
                    <img src={ HTrixieSketch } alt="" />
                    <p>Trixie Concept Sketches</p>
                  </a>
              </Lightbox>
            </GalleryImage>
            <GalleryImage className="cdesign art"  style={ HThumbWidth }>
              <Lightbox>
                  <a href="#6" className="hcreative1">
                    <img className="thumbnail" src={ HTrixie } alt="" />
                  </a>
                  <a href="#_" className="lightbox hcreative2" id="6">
                    <img src={ HTrixie } alt="" />
                    <p>Trixie Licious</p>
                  </a>
              </Lightbox>
            </GalleryImage>

            <GalleryImage className="illustration art"  style={ HThumbWidth }>
              <Lightbox>
                  <a href="#7" className="hcreative1">
                    <img className="thumbnail" src={ HOsawask } alt="" />
                  </a>
                  <a href="#_" className="lightbox hcreative2" id="7">
                    <img src={ HOsawask } alt="" />
                    <p>Osawask</p>
                  </a>
              </Lightbox>
            </GalleryImage>
          </Row>
        </Gallery>
      </CentralLayout>
    );
  }
}