import React from 'react';
import styled from 'styled-components';
import Button from '../button/Button.js';
import logo from '../../assets/logo/logo.svg';
//import ToggleBurgerButton from '../header/toggleburgerbutton.js';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink, Link } from "react-router-dom";
import { Fade } from '../global/GlobalStyle.js';

const NavStyle = styled.div`
  .navbar {
    background-color: transparent;
  }

  .navbar-toggler {
    color: #fff;
  }

  .navbar-collapse {
    text-align: center;
  }

  .nav-link {
    padding: 0px;
    display: inline;
  }

  @media screen and (min-width: 300px){
    .navbar {
      padding: 1em 1em;
    }

    .navbar-brand {
      width: 50%;
    }

    .header-logo {
      width: 60%;
    }
  }

  @media screen and (min-width: 480px){
    .navbar {
      padding: 1em 2em;
    }

    .header-logo {
      width: 50%;
    }
  }

  @media screen and only (max-width: 768px) {
    .navbar {
      padding: 1em 2em;
    }
    .navbar-brand {
      width: 70%;
    }

    .header-logo {
      width: 60%;
    }
  }

  @media screen and (min-width: 1200px){
    .navbar {
      padding: 1em 8em;
    }
  }
`;

// let headerShow;
//  if (window.location.pathname === '/') {
//     headerShow = {
//       opacity: 0
//     }
//   } else {
//     headerShow = {
//       opacity: 1
//     }
//   }
// <Navbar expand="lg" style={ headerShow }>

export class Header extends React.Component {


  render () {
    return (
      <NavStyle>
        <Navbar expand="lg" >
          <Navbar.Brand>
            <Fade>
              <Link to="/">
                <img src={logo} className="header-logo" alt="kooki mooki" />
              </Link>
            </Fade>
          </Navbar.Brand>
          <Navbar.Toggle className="navbar-dark" aria-controls="basic-navbar-nav" / >
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Fade>
                <Nav.Item>
                  <NavLink to="/me">
                    <Button primary>me</Button>
                  </NavLink>
                  <NavLink to="/art">
                    <Button primary>art</Button>
                  </NavLink>
                  <NavLink to="/design">
                    <Button primary>design</Button>
                  </NavLink>
                  <NavLink to="/web">
                    <Button primary>web</Button>
                  </NavLink>
                  {/* <Nav.Link href="/tatt">
                    <Button primary>tatt</Button>
                  </Nav.Link>  */}
                  <NavLink to="/connect">
                    <Button primary>connect</Button>
                  </NavLink>
                </Nav.Item>
              </Fade>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </NavStyle>
    );
  }
}