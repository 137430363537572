import styled from 'styled-components';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Fade } from '../global/GlobalStyle.js';

const CentralLayoutStyle = styled.div`
  text-align: center;
`;

export const CentralLayout = (props) => (
  <Container>
    <CentralLayoutStyle>
      <Row>
          <Col xs={12}>
            <Fade>
              {/* anything within the Container will be considered Props.children */}
              { props.children }
            </Fade>
          </Col>
      </Row>
    </CentralLayoutStyle>
  </Container>
)

