import React from 'react';
import { CentralLayout } from '../components/layouts/CentralLayout.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../components/button/Button.js';
import styled, { keyframes } from 'styled-components';
import { Gallery, GalleryImage } from '../components/layouts/Gallery.js';
import IndLogo from '../assets/cKpUNTueYJEXr.png';
import IndLogoPack from '../assets/cKpUNTueYJEXs.png';
import Isa from '../assets/cKpUNTueYJEXt.png';
import IsaIcon from '../assets/cKpUNTueYJEXi.png';
import { TitleBar } from '../components/global/GlobalStyle.js';
import Kit from '../assets/cKpUNTueYCFYk.png';
import Naloxone from '../assets/cKpUNTueYCFYn.png';
import Im4Icon from '../assets/cKpUNTemZFWi.png';
import Im4 from '../assets/cKpUNTemZFWm.png';
import EvecIcon from '../assets/cKpUNTueYCFYe.png';
import Evec from '../assets/cKpUNTueYCFYf.png';

export const fadeIn = keyframes`
  from {
    opacity: 0;
    scale(0.25);
  }

  to {
    opacity: 1;
    scale(1);
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 0;
    scale(1);
  }

  to {
    opacity: 1;
    scale(0.25);
  }
`;

const Lightbox = styled.div`
/** LIGHTBOX MARKUP **/

.thumbnail {
  width: 100%;
}

.lightbox {
  /** Default lightbox to hidden */
  display: none;

  /** Position and style */
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
  color: #fff;
}


// .lightbox img {
//   /** Pad the lightbox image */
//   max-width: 90%;
//   max-height: 80%;
//   margin-top: 10%;
// }

.lightbox:target {
  /** Remove default browser outline */
  outline: none;

  /** Unhide lightbox **/
  display: block;
  visibility: ${props => props.out ? 'hidden' : 'visible'};
  animation: ${props => props.out ? fadeOut : fadeIn} 0.25s linear;
  transition: visibility 0.25s linear;
}
`;

const VThumbWidth = {
  width: '100px',
  height: '100px'
}


const HThumbWidth = {
  width: '150px',
  height: '100px'
}

const Description = {
  marginTop: '15px'
}


const FullWidth = {
  height: '50%',
  width: 'auto',
}

const RowCenter = {
  justifyContent: 'center',
  marginLeft: '50px',
  marginRight: '50px'
}

const DivContain = {
  width: '80%',
  height: '80%',
  display: 'inline-block'
}

export class Design extends React.Component {
  constructor(props) {
    super(props);
    this.showCategory = this.showCategory.bind(this);
  }

  componentDidMount() {
    let imageList = [IndLogo, IndLogoPack, Isa, IsaIcon, Kit, Naloxone, Im4Icon, Im4]
    imageList.forEach((image) => {
        new Image().src = image
    });
  }

  showCategory(e) {
    const category = e.target.id;
    const all = document.getElementsByClassName('all');
    for (var i = 0; i < all.length; i++) {
      const classList = all[i].classList;

      for (var l = 0; l < classList.length; l++) {
        if ( (classList[l] === category) ) {
          if ( (all[i].hidden = true) ) {
            all[i].hidden = false;
            all[i].style.visibility = 'visible';
            break;
          } else {
            all[i].hidden = false;
            all[i].style.visibility = 'hidden';
            break;
          }
        } else {
          all[i].hidden = true;
          all[i].style.visibility = 'hidden';
        }
      }
    }
  }

  render () {
    return (
      <CentralLayout>
        <h1>Design</h1>
        <TitleBar />
        <br/>
        <Row>
          <Col>
            <Button id="branding" onClick={ this.showCategory }>Branding</Button>
            <Button id="wdesign" onClick={ this.showCategory }>Web Design</Button>
            <Button id="all" onClick={ this.showCategory }>All</Button>
          </Col>
        </Row>
        <br/>

      <Gallery>
          <Row style={ RowCenter }>

            <GalleryImage className="branding all" style={ VThumbWidth }>
              <Lightbox>
                <a href="#1">
                  <img className="thumbnail" src={ IndLogo } alt="" />
                </a>
                <a href="#_" className="lightbox" id="1">
                  <img src={ IndLogoPack } style={ FullWidth } alt="" />
                  <p style={ Description }>Small Business Branding</p>
                </a>
              </Lightbox>
            </GalleryImage>

            <GalleryImage className="branding all"  style={ HThumbWidth }>
              <Lightbox>
                  <a href="#2">
                    <img className="thumbnail" src={ IsaIcon } alt="" />
                  </a>
                  <a href="#_" className="lightbox" id="2">
                    <div style={ DivContain }>
                      <img src={ Isa } alt="" />
                      <p style={ Description }>XIX International Sociology Association 2018 Logo</p>
                    </div>
                  </a>
              </Lightbox>
            </GalleryImage>

            <GalleryImage className="wdesign all"  style={ VThumbWidth }>
              <Lightbox>
                  <a href="#3">
                    <img className="thumbnail" src={ Kit } alt="" />
                  </a>
                  <a href="#_" className="lightbox" id="3">
                    <div style={ DivContain }>
                      <img src={ Naloxone }  alt="" />
                      <p style={ Description }>Naloxone Training</p>
                    </div>
                  </a>
              </Lightbox>
            </GalleryImage>
            <GalleryImage className="wdesign all"  style={ VThumbWidth }>
              <Lightbox>
                  <a href="#4">
                    <img className="thumbnail" src={ Im4Icon } alt="" />
                  </a>
                  <a href="#_" className="lightbox" id="4">
                    <div style={ DivContain }>
                      <img src={ Im4 }  alt="" />
                      <p style={ Description }>Indigenous Matriarchs 4</p>
                    </div>
                  </a>
              </Lightbox>
            </GalleryImage>
            <GalleryImage className="branding all"  style={ VThumbWidth }>
              <Lightbox>
                  <a href="#5">
                    <img className="thumbnail" src={ EvecIcon } alt="" />
                  </a>
                  <a href="#_" className="lightbox" id="5">
                    <div style={ DivContain }>
                      <img src={ Evec } alt="" />
                      <p style={ Description }>East Vancouver Education Centre Logo</p>
                    </div>
                  </a>
              </Lightbox>
            </GalleryImage>
          </Row>
        </Gallery>
      </CentralLayout>
    );
  }
}