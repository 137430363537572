import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import { CentralLayout } from '../layouts/CentralLayout.js';
import { Link } from "react-router-dom";


const FooterStyle = styled.div`
  color: ${props => props.red || "#73272d"};
  font-weight: bold;
  text-align:center;
  margin-top 4em;
  margin-bottom: 4em;
  font-size: 0.8em !important;
  text-shadow: 0px 0.5px 2px #000;

  .subdue {
    color: #313f42;
  }
`;

export const Footer = () => (
 <FooterStyle>
  <Container>
    <CentralLayout>
      <Link to="/">
        home
      </Link>
      &nbsp;|&nbsp;
      <Link to="/me">
        me
      </Link>
      &nbsp;|&nbsp;
      <Link to="/art">
        art
      </Link>
      &nbsp;|&nbsp;
      <Link to="/design">
        design
      </Link>
      &nbsp;|&nbsp;
      <Link to="/web">
        web
      </Link>
      &nbsp;|&nbsp;
      <Link to="/connect">
        connect
      </Link>
    </CentralLayout>
    <p>{(new Date().getFullYear())} &copy; Kooki Mooki</p>
  </Container>
 </FooterStyle>
);

export default Footer;