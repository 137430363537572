// import React from 'react';
import styled, { keyframes } from 'styled-components';
// import { Fade } from '../global/GlobalStyle.js';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 0;
  }

  to {
    transform: scale(0.25);
    opacity: 1;
  }
`;

export const Gallery = styled.div `
  display: flex;
  flex-wrap: wrap;
  justify-content: center
`

export const GalleryImage = styled.div `
  visibility: ${props => props.out ? 'hidden' : 'visible'};
  animation: ${props => props.out ? fadeOut : fadeIn} 0.25s linear;
  transition: visibility 0.25s linear;
  margin: 5px;
`

const Lightbox = styled.div`
/** LIGHTBOX MARKUP **/

.thumbnail {
  max-width: 40%;
}

.lightbox {
  /** Default lightbox to hidden */
  display: none;

  /** Position and style */
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
  color: #fff;
}


.lightbox img {
  /** Pad the lightbox image */
  max-width: 90%;
  max-height: 80%;
  margin-top: 10%;
}

.lightbox:target {
  /** Remove default browser outline */
  outline: none;

  /** Unhide lightbox **/
  display: block;
  display: inline-block;
  visibility: ${props => props.out ? 'hidden' : 'visible'};
  animation: ${props => props.out ? fadeOut : fadeIn} 0.25s linear;
  transition: visibility 0.25s linear;
}
`;

export default { Gallery, GalleryImage, Lightbox};