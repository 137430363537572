import styled from 'styled-components';

const Button = styled.button`
  background: #001c21;
  color: white;

  font-size: 1em;
  margin: 0.25em;
  padding: 0.25em 1em;
  border: 2px solid #73272d;
  border-radius: 15px;
  font-weight: 600;

  :active, :hover {
    background: #73272d;
  }

  :focus {
    outline: 0;
  }
`;

export default Button;
